import { render } from "./AllocationView.vue?vue&type=template&id=7a93f442"
import script from "./AllocationView.vue?vue&type=script&lang=ts"
export * from "./AllocationView.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "7a93f442"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('7a93f442', script)) {
    api.reload('7a93f442', script)
  }
  
  module.hot.accept("./AllocationView.vue?vue&type=template&id=7a93f442", () => {
    api.rerender('7a93f442', render)
  })

}

script.__file = "src/views/AllocationView.vue"

export default script