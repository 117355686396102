
import { computed, defineComponent, ref } from 'vue'
import { useRoute } from 'vue-router'

import VariableTable from '@/components/variable/VariableTable.vue'
import { EMIT_EVENTS } from '@/constants/emits'
import { ModuleNames } from '@/constants/vuex'
import { Network } from '@/libs/bayes/Network'
import { Variable } from '@/libs/bayes/Variable'
import { normalizeRouterParamId } from '@/libs/utils'
import { initSurveyStatusesForUser } from '@/services/composition/survey'
import { useStore } from '@/store'
import { NetworkStateEnum } from '@/store/enums/states'
import { NetworkSchema, User } from '@/types'

const { NETWORK } = ModuleNames

// Deprecated
export default defineComponent({
  components: {
    VariableTable
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const currentNetwork = computed(() => store.state[NETWORK][NetworkStateEnum.CURRENT_NETWORK])
    const routerParams = route.params
    const workspaceId = normalizeRouterParamId(routerParams.workspaceId)
    const network = computed(() => new Network(currentNetwork.value as NetworkSchema))
    const selectedVariable = ref(network?.value?.variables[0])
    const selectVariable = (variable: Variable) => {
      selectedVariable.value = variable
    }

    const handleAllocation = (user: User) => {
      if (workspaceId && user.id) {
        initSurveyStatusesForUser(workspaceId, user.id)
      }
    }

    return {
      EMIT_EVENTS,
      handleAllocation,
      network,
      selectedVariable,
      selectVariable,
      workspaceId
    }
  }
})
