
import { message } from 'ant-design-vue'
import { computed, defineComponent } from 'vue'
import { NavigationGuard, NavigationGuardWithThis, useRoute } from 'vue-router'

import AllocationPage from '@/components/workspace/AllocationPage.vue'
import { MESSAGE } from '@/constants/message'
import { PATH } from '@/constants/router'
import { ModuleNames } from '@/constants/vuex'
import { useStore } from '@/store'
import {
  AllocationActionEnum,
  NetworkActionEnum,
  UserActionEnum,
  WorkspaceActionEnum
} from '@/store/enums/actions'
import { NetworkMutationEnum } from '@/store/enums/mutations/network'
import { AuthStateEnum } from '@/store/enums/states/auth'
import { vuexActions, vuexMutations } from '@/store/util'
import { NetworkList } from '@/types'

const loadPage: NavigationGuardWithThis<undefined> | NavigationGuard = async (to, from, next) => {
  const { workspaceId, surveyId } = to.params
  if (!Array.isArray(workspaceId) && !Array.isArray(surveyId)) {
    const store = useStore()
    const userId = store.state[ModuleNames.AUTH][AuthStateEnum.USER]?.id
    if (userId && workspaceId) {
      await store.dispatch(
        vuexActions(ModuleNames.WORKSPACE, WorkspaceActionEnum.GET_WORKSPACE),
        workspaceId
      )
      await store.dispatch(vuexActions(ModuleNames.USER, UserActionEnum.GET_USERS), {
        getAll: true
      })
      const getNetworks: any = store.dispatch(
        vuexActions(ModuleNames.NETWORK, NetworkActionEnum.GET_NETWORKS),
        workspaceId
      )
      const res: NetworkList = await getNetworks
      const networks = res?.content
      if (!networks.length) {
        message.error({
          content: MESSAGE.NETWORK_NOT_SET,
          duration: 3
        })
        next(false)
        return
      }
      store.commit(
        vuexMutations(ModuleNames.NETWORK, NetworkMutationEnum.GET_NETWORK_FULFILLED),
        networks[0]
      )
      // Get allocations
      await store.dispatch(
        vuexActions(ModuleNames.ALLOCATION, AllocationActionEnum.GET_ALLOCATIONS),
        workspaceId
      )
      next()
    } else {
      message.error({
        content: `Unexpected userId ${userId}. You will be redirect to home page in 3 seconds.`,
        duration: 3
      })
      setTimeout(() => {
        next(PATH.HOME)
      }, 3000)
    }
  } else {
    message.error({
      content: `Unexpected workspaceId ${workspaceId} & surveyId ${surveyId}. You will be redirect to home page in 3 seconds.`,
      duration: 3
    })
    setTimeout(() => {
      next(PATH.HOME)
    }, 3000)
  }
}

export default defineComponent({
  components: {
    AllocationPage
  },
  beforeRouteEnter: loadPage as NavigationGuard,
  beforeRouteUpdate: loadPage as NavigationGuardWithThis<undefined>,
  setup() {
    const route = useRoute()
    const workspaceId = computed(() => {
      if (typeof route.params.workspaceId === 'string') {
        return route.params.workspaceId
      }
      return null
    })
    return {
      workspaceId
    }
  }
})
